import React from 'react';
import brotherData from 'src/configs/botherhood.json';
import 'src/assets/styles/parts/Botherhood.css';

const Brotherhood = () => {
  const { brotherhoodImages } = brotherData;

  return (
    <div className="brotherhood-gallery">
      <h2>BOTHERHOOD</h2>
      <div className="brotherhood-grid">
        {brotherhoodImages.map((image) => (
          <div key={image.id} className="brotherhood-item">
            <img src={image.src} alt={image.alt} />
            <div className="brotherhood-overlay">
              <p>{image.alt}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Brotherhood;
