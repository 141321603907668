
function navigateTo(elementId: string, offset: number = 0) {
    console.log("Navigating to: " + elementId);
    const element = document.getElementById(elementId);
    console.log("Element: " + element);
    if (element) {
        console.log("Element found: ", element);
        window.scrollTo({
            top: element.offsetTop - offset,
            behavior: "smooth",
        });
    } else {
        console.error("Element not found: ", elementId);
    }
}

export {navigateTo};