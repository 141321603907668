import React, { useEffect, useRef } from "react";

import "../assets/styles/parts/AppWelcome.css";

import headerConfigs from "../configs/header.json";

import Star from "../assets/images/text-svg/3Star.svg";
import BNButton from "../assets/images/text-svg/BnButton.svg";
import BNDownBtnInner from "../assets/images/text-svg/DownBtnInner.svg";
import ListIcon from "../assets/images/text-svg/ListIcon.svg";
import Dot from "../assets/images/text-svg/Dot.svg";
import { navigateTo } from "../utils/NavUtils";
import { openBookNowWeb } from "../services/BookNow";

function AppWelcome() {
  const wallPaper = headerConfigs.wallpaper;
  const description = headerConfigs.description;

  const ServiceId = "service";
  const AboutId = "aboutUs";
  const StoryId = "story";
  const BarbersId = "barbers";
  const ContactId = "contact";

  const AWrapperRef = useRef<HTMLDivElement>(null);

  const [floating, setFloating] = React.useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setFloating(false);
        } else {
          setFloating(true);
        }
      },
      { threshold: 0 }
    );

    const observee = AWrapperRef.current;

    if (observee) {
      observer.observe(observee);
    }

    return () => {
      if (observee) {
        observer.unobserve(observee);
      }
    };
  }, [AWrapperRef]);

  const HeaderRef = useRef<HTMLDivElement>(null);

  const [floatingHeader, setFloatingHeader] = React.useState(0);

  const [isMobile, setIsMobile] = React.useState(false);
  const [isMobileNavOpen, setIsMobileNavOpen] = React.useState(false);

  useEffect(() => {
    const resize = () => {
      console.log(window.innerWidth);
      if (window.innerWidth < 768) {
        console.log("Mobile");
        setIsMobile(true);
      } else {
        console.log("Not mobile");
        setIsMobile(false);
      }
    };
    resize();
    window.addEventListener("resize", resize);
  }, [setIsMobile]);

  useEffect(() => {
    const observee = HeaderRef.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setFloatingHeader(0);
          if (observee) {
            observee.style.height = "fit-content";
            if (isMobile) {
              console.log("Down");
              setFloating(false);
            }
          }
        } else {
          setFloatingHeader(entry.target.children[0].clientHeight);
          if (observee) {
            if (isMobile) {
              console.log("Up");
              observee.style.height = "fit-content";
              setFloating(true);
            } else {
              observee.style.height =
                entry.target.children[0].clientHeight + "px";
            }
          }
        }
      },
      { threshold: 0 }
    );
    if (observee) {
      observer.observe(observee);
    }

    return () => {
      if (observee) {
        observer.unobserve(observee);
      }
    };
  }, [HeaderRef, isMobile]);

  return (
    <div
      className={"aw-wrapper"}
      style={{ backgroundImage: `url(${wallPaper})` }}
      id={"header"}
    >
      <div ref={HeaderRef} style={{ width: "100vw" }}>
        <div
          className={
            "h-wrapper" + (floatingHeader !== 0 && !isMobile ? " floatingNav" : "")
          }
        >
          <div className={"h-container"}>
            <div className="h-logo-container">
              <img
                src="https://res.cloudinary.com/dycsfcnso/image/upload/v1732757016/Thin%20barber/logo_r2xsxz.png"
                alt="THIN Barber Logo"
                className="h-logo-image"
              />
            </div>
            <div className={"h-nav-box-container"}>
              <div
                className={"h-nav-box"}
                onClick={() => {
                  navigateTo(ServiceId, floatingHeader);
                }}
              >
                <div className={"h-nav-box-title"}>Dịch vụ</div>
              </div>
              <div
                className={"h-nav-box"}
                onClick={() => {
                  navigateTo(AboutId, floatingHeader);
                }}
              >
                <div className={"h-nav-box-title"}>Về chúng tôi</div>
              </div>
              <div
                  className={"h-nav-box"}
                  onClick={() => {
                    navigateTo(StoryId, floatingHeader);
                  }}
              >
                <div className={"h-nav-box-title"}>Câu chuyện</div>
              </div>
              <div
                className={"h-nav-box"}
                onClick={() => {
                  navigateTo(BarbersId, floatingHeader);
                }}
              >
                <div className={"h-nav-box-title"}>Thợ cắt tóc</div>
              </div>
              <div
                className={"h-bn-button"}
                onClick={() => {
                  openBookNowWeb();
                }}
              >
                <div className={"h-bn-button-img"}>Đặt lịch ngay</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"w-wrapper"}>
        <div className={"w-three-star-wrapper"}>
          <img
            src={Star}
            alt={"sao"}
            style={{ width: "100%", height: "100%" }}
          />
        </div>
        <div className={"w-content"}>
          <div className="w-yc-text">MUỐN TÓC CHẤT, THIN LO TẤT</div>
          <div className={"w-c-inner"}>
            <div className={"w-description"}>{description}</div>
          </div>
          <div
            className={"h-bn-button"}
            onClick={() => {
              openBookNowWeb();
            }}
          >
            <div className={"h-bn-button-img"}>Đặt lịch ngay</div>
          </div>
        </div>
        <div
          ref={isMobile ? null : AWrapperRef}
          style={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          <div
            className={"w-down-btn-wrapper" + (floatingHeader !== 0 ? " floatingBtn" : "")}
          >
            <div
              className={"w-down-btn"}
              onClick={() => {
                if (floatingHeader !== 0) {
                  navigateTo("header");
                } else {
                  navigateTo(ServiceId);
                }
              }}
            >
              <img
                className={"w-down-btn-inner"}
                src={BNDownBtnInner}
                alt={"nút xuống"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={"mb-nav-btn" + (isMobileNavOpen ? " mb-nav-open" : "")}>
        <div className={"mb-nav-content"}>
          <div className={"mb-nav-list"}>
            <div
              className={"mb-nav-item"}
              onClick={() => {
                navigateTo(ServiceId);
                setIsMobileNavOpen(false);
              }}
            >
              <img className={"mb-nav-i-decor"} src={Dot} alt={"chấm"} />
              Dịch vụ
            </div>
            <div
              className={"mb-nav-item"}
              onClick={() => {
                navigateTo(AboutId);
                setIsMobileNavOpen(false);
              }}
            >
              <img className={"mb-nav-i-decor"} src={Dot} alt={"chấm"} />
              Về chúng tôi
            </div>
            <div
              className={"mb-nav-item"}
              onClick={() => {
                navigateTo(StoryId);
                setIsMobileNavOpen(false);
              }}
            >
              <img className={"mb-nav-i-decor"} src={Dot} alt={"chấm"} />
              Câu chuyện
            </div>
            <div
                className={"mb-nav-item"}
                onClick={() => {
                  navigateTo(BarbersId);
                  setIsMobileNavOpen(false);
                }}
            >
              <img className={"mb-nav-i-decor"} src={Dot} alt={"chấm"} />
              Thợ cắt tóc
            </div>
            <div
              className={"mb-nav-item"}
              onClick={() => {
                navigateTo(ContactId);
                setIsMobileNavOpen(false);
              }}
            >
              <img className={"mb-nav-i-decor"} src={Dot} alt={"chấm"} />
              Liên hệ
            </div>
          </div>
          <div
            className={"mb-nav-bn-button"}
            onClick={() => {
              openBookNowWeb();
            }}
          >
            <img
              className={"mb-nav-bn-img"}
              src={BNButton}
              alt={"Nút đặt lịch"}
            />
          </div>
        </div>
        <div style={{width: "0", position: "relative"}}>
          <div
              className={"mb-nav-o-b-container"}
              onClick={() => setIsMobileNavOpen((prevState) => !prevState)}
          >
            <img
                className={"mb-nav-o-b-img"}
                src={ListIcon}
                alt={"biểu tượng danh sách"}
            />
          </div>
          <div className={"mb-nav-logo-display-container" + (floating ? "" : " hidden")}>
            <div className={"mb-nav-logo-display"}>
              <img
                  src="https://res.cloudinary.com/dycsfcnso/image/upload/v1732757016/Thin%20barber/logo_r2xsxz.png"
                  alt="THIN Barber Logo"
              />
            </div>
          </div>
        </div>
      </div>
      <div ref={isMobile ? AWrapperRef : null}>
        <div
          className={"mb-down-btn-wrapper" + (floating ? " floatingBtn" : "")}
        >
          <div
            className={"mb-down-btn"}
            onClick={() => {
              if (floating) {
                navigateTo("header");
              } else {
                navigateTo(ServiceId);
              }
            }}
          >
            <img
              className={"mb-down-btn-inner"}
              src={BNDownBtnInner}
              alt={"nút xuống"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppWelcome;
