import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BlogCard from '../components/BlogCard';
import blogData from '../configs/blogData.json';
import '../assets/styles/parts/Blog.css';

const BlogList: React.FC = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = blogData.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const handleBlogClick = (id: number) => {
    navigate(`/blog/${id}`);
  };

  return (
    <div className="blog-list-container">
      <h2 className="blog-list__title">BLOG</h2>
      <div className="blog-list">
        {currentItems.map((blog) => (
          <div 
            key={blog.id} 
            onClick={() => handleBlogClick(blog.id)}
            style={{ cursor: 'pointer' }}
          >
            <BlogCard
              id={blog.id}
              images={blog.cover_images}
              title={blog.title}
              subtitle={blog.subtitle}
              time={blog.time}
              content={blog.content}
            />
          </div>
        ))}
      </div>
      <div className="pagination">
        {Array.from({ length: Math.ceil(blogData.length / itemsPerPage) }, (_, i) => i + 1).map(
          (pageNumber) => (
            <button
              key={pageNumber}
              onClick={() => paginate(pageNumber)}
              className={currentPage === pageNumber ? 'active' : ''}
            >
              {pageNumber}
            </button>
          )
        )}
      </div>
    </div>
  );
};

export default BlogList;