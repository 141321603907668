import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./assets/styles/App.css";
import Background from "./components/Background";
import OurServices from "./parts/OurServices";
import OurStory from "./parts/OurStory";
import Contact from "./parts/Contact";
import Stylish from "./parts/Stylish";
import AppWelcome from "./parts/AppWelcome";
import Brotherhood from "./parts/Botherhood";
import Blog from "./parts/Blog";
import BlogDetail from "./parts/BlogDetail";

function setRootFontSize(size: number) {
  document.documentElement.style.fontSize = `${size}px`;
}

function App() {
  const recalculateRem = () => {
    console.log(window.screen.width);
    const width = window.screen.width;
    setRootFontSize(width / 1920);
    const aspectRatio = window.screen.width / window.screen.height;
    if (aspectRatio > 1.5) {
      console.log(window.screen.width);
      console.log(width);
      console.log("Aspect ratio is greater than 1.5");
    } else {
      console.log(window.screen.width);
      console.log(width);
      console.log("Aspect ratio is less than 1.5");
    }
  };
  useEffect(() => {
    recalculateRem();
    window.addEventListener("resize", () => {
      recalculateRem();
    });
  });

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Background>
              <AppWelcome />
              <OurServices />
              <OurStory />
              <Brotherhood />
              <Stylish />
              <Blog />
              <Contact />
            </Background>
          }
        ></Route>
        <Route path="/blog/:id" element={<BlogDetail />} />
      </Routes>
    </Router>
  );
}

export default App;
