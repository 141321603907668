import React from 'react';

import '../assets/styles/component/Background.css';

interface BackgroundProps {
    children: React.ReactNode;
}

function Background(props: BackgroundProps) {
    // children of the Background component
    console.log("Hello from Background");
    return (
        <div className="Background" id={"body"}>
            {props.children}
        </div>
    );
}

export default Background;