import React from "react";

import "../assets/styles/parts/AboutUs.css";
import BarberDisplay from "../components/BarberDisplay";

function Stylish() {
  return (
    <div className="au-wrapper" id="barbers">
      <div className="au-wrapper-title">
        <div className="au-wrapper-title-main">ĐỘI NGŨ BARBER</div>
        <div className="au-wrapper-title-sub">
          CÁC ANH THỢ CHẤT CỦA CHÚNG MÌNH
        </div>
      </div>
      <div className="au-content-wrapper">
        <BarberDisplay />
      </div>
    </div>
  );
}

export default Stylish;
