import React from "react";

import "../assets/styles/parts/Contact.css";

import ContactUs from "../assets/images/text-svg/ContactUs.svg";
import Address from "../assets/images/text-svg/Place.svg";
import Email from "../assets/images/text-svg/Mail.svg";
import Phone from "../assets/images/text-svg/Phone.svg";
import WorkHours from "../assets/images/text-svg/Clock.svg";
import AddressText from "../assets/images/text-svg/AddressText.svg";
import EmailText from "../assets/images/text-svg/EmailText.svg";
import PhoneText from "../assets/images/text-svg/PhoneText.svg";
import WorkHoursText from "../assets/images/text-svg/WorkingHoursText.svg";
import Copyright from "../assets/images/text-svg/Copyright.svg";

import contactConfig from "../configs/contact.json";

function Contact() {

    return (
        <div className={"cu-wrapper"} id={"contact"}>
            <div className={"cu-container"}>
                <div className={"cu-title-wrapper"}>
                    <div className={"cu-title"}>
                        <img src={ContactUs} alt={"Contact Us"} className={"cu-title-img"}/>
                    </div>
                </div>
                <div className={"cu-content-wrapper"}>
                    <div className={"address content-common"}>
                        <img className={"icon"} src={Address} alt={"Address"}/>
                        <img className={"text"} src={AddressText} alt={"Address Text"}/>
                        {getAddress()}
                    </div>
                    <div className={"email content-common"}>
                        <img className={"icon"} src={Email} alt={"Email"}/>
                        <img className={"text"} src={EmailText} alt={"Email Text"}/>
                        <div className={"content-item-common item-text"}>
                            {contactConfig.email}
                        </div>
                    </div>
                    <div className={"phone content-common"}>
                        <img className={"icon"} src={Phone} alt={"Phone"}/>
                        <img className={"text"} src={PhoneText} alt={"Phone Text"}/>
                        <div className={"content-item-common item-text"}>
                            {contactConfig.phone}
                        </div>
                    </div>
                    <div className={"work-hours content-common"}>
                        <img className={"icon"} src={WorkHours} alt={"Work Hours"}/>
                        <img className={"text"} src={WorkHoursText} alt={"Work Hours Text"}/>
                        <div className={"content-item-common item-text"}>
                            {getWorkHours()}
                        </div>
                    </div>
                </div>
            </div>
            <div className={"cu-separator"}/>
            <img className={"cu-copyright"} src={Copyright} alt={"Copy Right"}/>
        </div>
    );
}

function getWorkHours() {
    return contactConfig.workingHours.map((workHour, index) => {

        return (
            <div key={index}>
                {workHour}
            </div>
        );
    });
}

function getAddress() {
    return contactConfig.address.map((address, index) => {

        return (
            <div key={index} className={"content-item-common item-text"}>
                {address}
            </div>
        );
    });
}

export default Contact;